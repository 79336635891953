<template>
  <div>
    <!-- //第一个卡片 -->
    <el-card v-if="digital == 1">
      <el-card>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="项目名称:">
            <el-input placeholder="请输入项目名称" v-model="pro" size="small"></el-input>
          </el-form-item>
          <!-- <el-form-item label="公司名称:">
            <el-input
              placeholder="请输入公司名称"
              v-model="name"
              size="small"
            ></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" size="small" style="margin-left: 15px" @click="query">查询</el-button>
            <el-button type="warning" size="small" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <!-- //表单 -->
        <el-table :data="tableData" stripe style="width: 100%"
          :header-cell-style="{ background: '#0000CD', color: '#ffffff' }">
          <el-table-column prop="pro_name" label="项目名称"> </el-table-column>
          <el-table-column prop="company" label="所属公司"> </el-table-column>
          <el-table-column prop="area" label="所属区域"> </el-table-column>
          <el-table-column prop="num_count" label="归档完成度%">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" @click="chakan(scope.row)">查看详情</el-link>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
          :page-sizes="[5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </el-card>
    </el-card>
    <!-- day2第二个卡片 --><el-card v-else-if="digital == 2">
      <el-button type="primary" round @click="superior" class="butt" size="mini">返回上一级</el-button>
      <div class="con-size">
        <p>
          项目名称: <span style="font-size: 14px">{{ aaa1.pro_name }}</span>
        </p>
        <p>
          所属公司: <span style="font-size: 14px">{{ aaa1.company }}</span>
        </p>
        <p>
          所在区域: <span style="font-size: 14px">{{ aaa1.area }}</span>
        </p>
        <p class="pp">
          体系文件完成度:
          <span style="font-size: 14px">{{ aaa1.num_count }}</span>
        </p>
      </div>

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="文件分类:">
          <el-select placeholder="全部" v-model="file_type" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件名称:">
          <el-input placeholder="请输入文件名称" v-model="file_name" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" style="margin-left: 15px" @click="queryday2">查询</el-button>
          <el-button type="warning" size="small" @click="resetday2">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- //表单 -->


      <el-dialog title="文件归档库" :visible.sync="dialogTableVisible">
        <el-table :data="gridData">
          <el-table-column property="name" label="文件名" width="200"></el-table-column>
          <el-table-column property="path" label="下载连接">
            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="xiazai(scope.row.path)">链接</el-button>
              </div>
            </template>
          </el-table-column>

          <el-table-column property="path" label="操作">
            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="shanchu(scope.row)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <!-- <el-button type="warning" size="small" @click="sub">文件库</el-button> -->
      <el-table :data="tableData2" stripe style="width: 100%"
        :header-cell-style="{ background: '#0000CD', color: '#ffffff' }" :cell-style="cellStyle">
        <el-table-column prop="classify" label="分类"> </el-table-column>
        <el-table-column prop="archive_file" label="归档文件名称及内容">
        </el-table-column>
        <el-table-column prop="com_upload" label="保存单位/企业">
          <template slot-scope="scope">
            <div v-if="['作业活动风险清单', '作业活动风险评价记录表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(1)">查看</el-button>
            </div>
            <div v-else-if="['设备设施风险清单', '设备设施风险评价记录表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(2)">查看</el-button>
            </div>
            <div v-else-if="['基础管理类隐患排查清单', '生产现场类隐患排查清单'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(11)">查看</el-button>
            </div>
            <div v-else-if="['作业活动分级管控清单', '设备设施分级管控清单'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(3)">查看</el-button>
            </div>
            <div v-else-if="['重大风险管控统计表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(10)">查看</el-button>
            </div>
            <div v-else-if="['隐患排查记录表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(12)">查看</el-button>
            </div>
            <div v-else-if="['双重预防体系更新记录'].includes(scope.row.archive_file)">
            </div>
            <div
              v-else-if="['双重预防体系工作小组成立文件', '项目培训效果评价', '项目培训签到表', '项目培训考试试卷', '项目教育培训记录', '岗位安全风险明白卡', '风险控制措施评审记录表'].includes(scope.row.archive_file)">

            </div>
            <div v-else-if="['区域安全风险四色分布图'].includes(scope.row.archive_file)">
              <el-button type="text" v-popover:popover4 @click="cli(4)">查看</el-button>
              <el-popover ref="popover4" placement="right" width="400" trigger="click">
                <img :src=img alt="">
              </el-popover>

            </div>
            <div v-else>
              <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/document/crt/"
                :on-success="handlePreview" :on-error="on_off" :headers="hear" :data="data_list" multiple :limit="3"
                :file-list="fileList" style="float: left;">

                <span v-if='scope.row.company_url != 0' style="padding-right: 30px;color: #5fce00;">已上传{{
                  scope.row.company_url }}个文件</span>
                <span v-else style="padding-right: 52px;color: red;">未上传文件</span>
                <el-button type="text" @click="add_file(scope.row.id, 0)">上传</el-button>

              </el-upload>

              <el-button type="text" @click="aaaa(scope.row.id, 0)"><span
                  style="padding-left: 30px;">查看</span></el-button>


              <el-button type="text" @click="bbbb(scope.row.id, 0)"><span
                  style="padding-left: 30px;">删除</span></el-button>

            </div>

            <!-- <span v-if="scope.row.com_upload" 
            
              :on-exceed="handleExceed"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              @click="downloa('name',scope.row)" style="margin-left:20px;">下载</span> -->
          </template>
        </el-table-column>
        <el-table-column prop="pro_upload" label="保存单位/项目">
          <template slot-scope="scope">

            <el-upload class="upload-demo" action="http://139.9.0.126:8088/api/document/crt/" :on-success="handlePreview"
              :headers="hear" :data="data_list" multiple :limit="3" :file-list="fileList" style="float: left;">
              <div v-if="['作业活动风险清单', '作业活动风险评价记录表'].includes(scope.row.archive_file)">
              </div>
              <div
                v-else-if="['企业培训效果评价', '企业培训签到表', '企业培训考试表', '企业教育培训记录', '双重预防体系领导小组及工作机构成立文件'].includes(scope.row.archive_file)">

              </div>
              <div v-else-if="['设备设施风险清单', '设备设施风险评价记录表'].includes(scope.row.archive_file)">
              </div>
              <div v-else-if="['基础管理类隐患排查清单', '生产现场类隐患排查清单'].includes(scope.row.archive_file)">
              </div>
              <div v-else-if="['作业活动分级管控清单', '设备设施分级管控清单'].includes(scope.row.archive_file)">
              </div>
              <div v-else-if="['重大风险管控统计表'].includes(scope.row.archive_file)">
              </div>
              <div v-else-if="['隐患排查记录表'].includes(scope.row.archive_file)">
              </div>
              <div v-else-if="['作业安全风险比较图'].includes(scope.row.archive_file)">
              </div>
              <div v-else-if="['双重预防体系更新记录'].includes(scope.row.archive_file)">

              </div>
              <div v-else>


                <span v-if='scope.row.project_url != 0' style="padding-right: 30px;color: #5fce00;">已上传{{
                  scope.row.project_url }}个文件</span>
                <span v-else style="padding-right: 52px;color: red;">未上传文件</span>
                <el-button type="text" @click="add_file(scope.row.id, 1)">上传</el-button>
              </div>
            </el-upload>
            <div v-if="['作业活动风险清单', '作业活动风险评价记录表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(1)">查看</el-button>
            </div>
            <div v-else-if="['设备设施风险清单', '设备设施风险评价记录表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(2)">查看</el-button>
            </div>
            <div
              v-else-if="['企业培训效果评价', '企业培训签到表', '企业培训考试表', '企业教育培训记录', '双重预防体系领导小组及工作机构成立文件'].includes(scope.row.archive_file)">

            </div>
            <div v-else-if="['基础管理类隐患排查清单', '生产现场类隐患排查清单'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(11)">查看</el-button>
            </div>
            <div v-else-if="['作业活动分级管控清单', '设备设施分级管控清单'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(3)">查看</el-button>
            </div>
            <div v-else-if="['重大风险管控统计表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(10)">查看</el-button>
            </div>
            <div v-else-if="['隐患排查记录表'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(12)">查看</el-button>
            </div>
            <div v-else-if="['作业安全风险比较图'].includes(scope.row.archive_file)">
              <el-button type="text" @click="cli(13)">查看</el-button>
            </div>
            <div v-else-if="['双重预防体系更新记录'].includes(scope.row.archive_file)" style=" color: #000000b0;">
              {{ dangqianshijian }}
            </div>
            <div v-else>
              <el-button type="text" @click="aaaa(scope.row.id, 1)"><span
                  style="padding-left: 30px;">查看</span></el-button>

              <el-button type="text" @click="bbbb(scope.row.id, 1)"><span
                  style="padding-left: 30px;">删除</span></el-button>
            </div>
          </template>

        </el-table-column>

      </el-table>
    </el-card>
  </div>
</template>

<script>
import router from "../../router/index.js"
import {
  addstatistics,
  addstatistics2, addfilse, sise, get_file_path, del_file_path,
  wenjian_update
} from "../../request/statistics";
export default {
  name: "",
  props: [],
  data() {
    return {
      dangqianshijian: '',
      gongsiorxiangmue: '',
      gongsiorxiangmu: '',
      dialogTableVisible: false,
      hear: {
        'Authorization': localStorage.getItem('TOKEN')
      },
      data_list: {
        'documentgs': localStorage.getItem('companys'),
        'documentxm': localStorage.getItem('corporations'),
      },
      gridData: [
      ],
      fileList: [],
      tableData: [],
      tableData2: [],
      aaa1: {},
      digital: 1, //用户卡片切换
      total: 0,
      page: 1,
      pagesize: 10,
      name: "", //公司名字
      pro: "", //项目名字
      id: "", //存储项目id
      options: [
        {
          value: "制度建设",
          lable: "制度建设",
        },
        {
          value: "教育培训",
          lable: "教育培训",
        },
        {
          value: "组织机构",
          lable: "组织机构",
        },
        {
          value: "评估更新",
          lable: "评估更新",
        },
        {
          value: "隐患排查",
          lable: "隐患排查",
        },
        {
          value: "风险管控",
          lable: "风险管控",
        },
      ],
      file_type: "", //文件分类
      file_name: "", //文件名称
      file_loging: true,
      fileurl: '',
      fileid: '',
      com: '',
      img: '',
      pjtid: '',
    };
  },
  watch: {
    file_loging(newName, oldName) {
      if (newName == false) {
        console.log(this.fileurl, this.fileid, this.com)
        this.file_loging = true
        let msg = {
          com_pro_url: this.fileurl,
          id: this.fileid,
          com_pro: this.com,
        };
        const res = addfilse(msg);
        console.log(res)
      } else {
        console.log('重置状态')
      }
    },

    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.digital = 1
      this.shuju();
      // 获取当前时间
      const myDate = new Date()
      //获取当前年
      const year = myDate.getFullYear()
      //获取当前月
      const month = myDate.getMonth() + 1
      //获取当前日
      const date = myDate.getDate()

      //获取当前时间
      this.dangqianshijian = '最后更新时间： ' +
        year +
        '年' +
        this.convert(month) +
        '月' +
        this.convert(date) + '日'
    },
  },

  methods: {

    async shanchu(e) {
      for (var i = 0; i < this.gridData.length; i++) {
        if (e.path == this.gridData[i].path) {
          this.gridData.splice(i, 1)
        }
      }
      console.log(this.gridData)
      console.log(this.gongsiorxiangmu)
      console.log(this.gongsiorxiangmue)
      var data_list = []
      for (var i = 0; i < this.gridData.length; i++) {
        data_list.push(this.gridData[i].path)
      }
      var msg = {
        id: this.gongsiorxiangmue,
        data: data_list,
        pro_com: this.gongsiorxiangmu
      }
      const res = await wenjian_update(msg);
      console.log(res)
      if (res.data.code == 200) {
        this.$notify({
          title: '成功',
          message: res.data.msg,
          type: 'success'
        });

        this.shuju();
      }

    },
    async bbbb(e, a) {
      let msg = {
        id: e,
        types: a
      };
      const res = await del_file_path(msg);
      console.log(res)
      if (res.data.code == 200) {
        this.$notify({
          title: '成功',
          message: res.data.msg,
          type: 'success'
        });
        this.shuju();
      }
    },
    async aaaa(e, a) {
      console.log(e)
      this.gongsiorxiangmue = e
      this.gongsiorxiangmu = a
      this.gridData = []
      let msg = {
        pro_id: this.id,
        id: e,
        types: a
      };
      const res = await get_file_path(msg);
      console.log(res)
      this.gridData = res.data.data
      this.dialogTableVisible = true

    },
    xiazai(e) {
      console.log(e)
      window.open(e, '_blank')
    },
    async sub() {
      this.dialogTableVisible = true
      let msg = {
        pro_id: localStorage.getItem('corporations'),
      };
      const res = await get_file_path(msg);
      console.log(res)
      this.gridData = res.data.data
    },
    async cli(e) {
      if (e == 1) {
        this.$router.push('/Screening?size=6')
      }
      if (e == 11) {
        this.$router.push('/Hidden')
      }
      if (e == 2) {
        this.$router.push('/Screeningsb?size=2')
      }
      if (e == 3) {
        this.$router.push('/Screening?size=3')
      }
      if (e == 12) {
        this.$router.push('/RecordsFebruary')
      }
      if (e == 5) {
        this.$router.push('/Screening?size=1')
      }
      if (e == 6) {
        this.$router.push('/Screeningsb?size=4')
      }
      if (e == 13) {
        this.$router.push('/visualization')
      }
      if (e == 10) {
        this.$router.push('/SignificantRisk')
      }
      if (e == 4) {

        let msg = {
          pro_id: localStorage.getItem('corporations'),
        };
        const res = await sise(msg);

        this.img = res.data.img
      }
    },
    handlePreview(response, file, fileList) {
      console.log(response.res);
      this.fileurl = response.res
      this.file_loging = false
    },
    async add_file(id, com_pro) {
      console.log(id, com_pro)
      console.log('上传文件')
      this.fileid = id
      this.com = com_pro

    },
    async shuju() {
      let msg = {
        page: this.page,
        size: this.pagesize,
        name: this.pro,
        unique_identification: localStorage.getItem("uid"),
        // unique_identification:localStorage.getItem("uid"),
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }
      const res = await addstatistics(msg);
      //  console.log(res,258)
      this.total = res.data.count;
      this.tableData = res.data.msg;
    },
    //分页

    handleSizeChange(pagesize) {
      // console.log(`每页 ${pagesize} 条`);
      this.pagesize = pagesize;
      this.shuju();
    },
    handleCurrentChange(page) {
      // console.log(`当前页: ${page}`);
      this.page = page;
      this.shuju();
    },
    //查询
    query() {
      this.page = '1'
      this.shuju();
    },
    //重置
    reset() {
      this.name = "";
      this.pro = "";
      this.shuju();
    },
    //查看详情
    chakan(row) {
      this.id = row.id;
      this.aaa1.pro_name = row.pro_name;
      this.aaa1.company = row.company;
      this.aaa1.area = row.area;
      this.aaa1.num_count = row.num_count;
      this.digital = 2;
      this.chsidfhid();
    },
    async chsidfhid() {
      let msg = {
        file_type: this.file_type,
        file_name: this.file_name,
        pro_id: this.id,
      };
      const res = await addstatistics2(msg);
      //  console.log(res,888888)
      this.tableData2 = res.data.msg;
      // console.log(this.tableData2,);
      for (let i = 0; i < this.tableData2.length; i++) {
        this.tableData2[i].com_upload = parseInt(this.tableData2[i].com_upload);
        this.tableData2[i].pro_upload = parseInt(this.tableData2[i].pro_upload);
      }
    },
    //返回上一级
    superior() {
      this.digital = 1;
    },

    // 控制表格风险级别文字颜色
    cellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // console.log(row);
      // console.log(row.column);
      if (row.column.label == "保存单位/企业" && row.row.com_upload == "1") {
        return "color:blue";
      } else if (
        row.column.label == "保存单位/企业" &&
        row.row.com_upload == "0"
      ) {
        return "color:red";
      } else if (
        row.column.label == "保存单位/项目" &&
        row.row.pro_upload == "1"
      ) {
        return "color:blue";
      } else if (
        row.column.label == "保存单位/项目" &&
        row.row.pro_upload == "0"
      ) {
        return "color:red";
      }
    },
    //day2查询
    queryday2() {

      this.chsidfhid();
    },
    //day2重置
    resetday2() {
      this.file_type = "";
      this.file_name = "";
      this.chsidfhid();
    },
    convert(val) {
      return val < 10 ? '0' + val : val
    },
    on_off() {

      router.replace({ path: '/Login' })
      this.$message.error('您的账号被其他人登录，请重新登录');
    }
  },
  mounted() {
    // this.pjtid = this.$route.query.id
    // console.log(this.pjtid, '11111111');

    this.shuju();
    // 获取当前时间

    const myDate = new Date()
    //获取当前年
    const year = myDate.getFullYear()
    //获取当前月
    const month = myDate.getMonth() + 1
    //获取当前日
    const date = myDate.getDate()

    //获取当前时间
    this.dangqianshijian = '最后更新时间： ' +
      year +
      '年' +
      this.convert(month) +
      '月' +
      this.convert(date) + '日'


    //日期时间处理

  },
  components: {},
  computed: {},
};
</script>
<style lang='less' scoped>
.el-form-item {
  margin-bottom: 0px;

}

.el-table {
  margin-top: 10px;
  border-radius: 5px;
}

.con-size {
  width: 100%;
  height: 50px;
}

.con-size p {
  width: 25%;
  height: 30px;
  float: left;
  line-height: 30px;
  // border-bottom: 1px solid black;
}

.pp {
  text-align: center;
}
</style>
 
