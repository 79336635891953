//隐患排查
import { request } from "./request"

// 渲染隐患排查数据
export function addstatistics(msg) {
    return request({
        url: "v3/archive/",
        method: "get",
        params: msg,
    })

}
// day2
export function addstatistics2(msg) {
    return request({
        url: "v1/archive/",
        method: "post",
        data: msg,
    })

}

// 上传文件
export function addfilse(msg) {
    return request({
        url: "v1/archive_file/",
        method: "post",
        data: msg,
    })

}


// 四色图
export function sise(msg) {
    return request({
        url: "api/Pro_item/",
        method: "post",
        data: msg,
    })

}


// 文件 下载
export function get_file_path(msg) {
    return request({
        url: "api/get_file_path/",
        method: "post",
        data: msg,
    })
}



// 文件 删除
export function del_file_path(msg) {
    return request({
        url: "api/del_file_all/",
        method: "post",
        data: msg,
    })
}



// 文件 删除
export function wenjian_update(msg) {
    return request({
        url: "api/wenjian_update/",
        method: "post",
        data: msg,
    })
}